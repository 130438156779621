import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import "./noPermissionMessage.scss";

export default function NoPermissionMessage() {
  return (
    <div className="noPermissionMessage">
      <div className="is-flex is-justify-content-center">
        <div>
          <FontAwesomeIcon icon={faLock} className="lock-icon circle" />
        </div>
      </div>
      <div className="is-flex is-justify-content-center">
        <article className="message is-danger">
          <div className="message-header">
            <p>403 - Zugriff verweigert</p>
          </div>
          <div className="message-body is-flex is-flex-direction-column is-justify-content-center is-align-content-center">
            <span className="has-text-centered">
              Sie haben nicht die nötigen Berechtigungen um diese Seite zu
              sehen.
            </span>
            <span className="has-text-centered">
              Kontaktieren Sie Ihren Administrator um Zugriff zu erhalten oder
              gehen Sie zurück zur <Link to="/">Startseite</Link>.
            </span>
          </div>
        </article>
      </div>
    </div>
  );
}
