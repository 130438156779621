import SystemOverview from "./system-overview/SystemOverview";
import { Navigate, Route, Routes } from "react-router-dom";
import "@e21x/autobahn-styleguide";
import "./index.scss";
import AdminPanel from "./admin-panel/AdminPanel";
import useAutoSignIn from "./auth/useAutoSignIn";
import { useAuth } from "react-oidc-context";
import PageNotFound from "./ui/page-not-found/PageNotFound";

export default function App() {
  const auth = useAuth();
  useAutoSignIn();

  if (!auth.isAuthenticated) {
    console.error("not authenticated");
    return null;
  }

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<SystemOverview />}></Route>
        <Route path="/admin" element={<AdminPanel />}></Route>
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </div>
  );
}
