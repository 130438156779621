import { useMemo } from "react";
import AppBar from "../ui/app-bar/AppBar";
import { useGetConfiguration } from "../react-query/app-group/AppGroupApi";
import ConfigurationEditor from "./ConfigurationEditor";
import { useAuthUser } from "../auth/useAuthUser";
import NoPermissionMessage from "../ui/UnauthorizedMessage/NoPermissionMessage";

export default function AdminPanel() {
  const { isLoading, isLoadingError, error, isSuccess, data } =
    useGetConfiguration();

  const { hasAnyRoles } = useAuthUser();

  const hasPermissionForConfiguration = hasAnyRoles([
    "AppStoreKonfiguration_schreiben",
    "AppStoreKonfiguration_lesen",
  ]);

  const content = useMemo(() => {
    if (!hasPermissionForConfiguration) {
      return <NoPermissionMessage />;
    }

    if (isLoading) {
      return <div>Lädt...</div>;
    }
    if (isLoadingError) {
      return (
        <>
          <div>{error?.message}</div>
          <div>{error?.body?.message}</div>
        </>
      );
    }
    if (isSuccess && data) {
      return <ConfigurationEditor data={data} />;
    }
  }, [
    hasPermissionForConfiguration,
    isLoading,
    isLoadingError,
    isSuccess,
    data,
    error?.message,
    error?.body?.message,
  ]);

  return (
    <div className="has-background-white">
      <AppBar />
      <section className="container section">
        <div>{content}</div>
      </section>
    </div>
  );
}
