import { faPencil, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./floatingLink.scss";
import { Link } from "react-router-dom";

interface FloatingLinkProps {
  icon?: IconDefinition;
  link: string;
  isBottom?: boolean;
}

export default function FloatingLink({
  icon = faPencil,
  link,
}: FloatingLinkProps) {
  return (
    <Link to={link} className="floating-link" role="button" tabIndex={0}>
      <FontAwesomeIcon icon={icon} />
    </Link>
  );
}
