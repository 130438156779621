import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function SignOutButton() {
  return (
    <Link to="/logout" className="navbar-item button-fa is-clickable">
      <span className="icon mr-1">
        <FontAwesomeIcon icon={faSignOut} />
      </span>
    </Link>
  );
}
