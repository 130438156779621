import { useMemo, useState } from "react";
import SystemOverviewGroup from "./SystemOverviewGroup";
import { useGetConfiguration } from "../react-query/app-group/AppGroupApi";
import AppGroup from "../react-query/app-group/AppGroup";
import AutobahnBackground from "../ui/AutobahnBackground/AutobahnBackground";
import { useAuthUser } from "../auth/useAuthUser";
import FloatingLink from "../ui/floating-link/FloatingLink";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import logo from "@e21x/autobahn-styleguide/assets/logo_white.svg";

export default function SystemOverview() {
  const [activeItemId, setActiveItemId] = useState<string | null>(null);

  const { isLoading, isError, isSuccess, data, error } = useGetConfiguration();

  const content = useMemo(() => {
    if (isLoading) {
      return <div className="has-text-white">Lädt...</div>;
    }
    if (isError) {
      return (
        <>
          <div className="has-text-white">{error?.message}</div>
          <div className="has-text-white">{error?.body?.message}</div>
        </>
      );
    }
    if (isSuccess && data) {
      return data.map((group: AppGroup) => (
        <SystemOverviewGroup
          key={group.title}
          group={group}
          activeItemId={activeItemId}
          setActiveItemId={setActiveItemId}
        />
      ));
    }
    return null;
  }, [isLoading, isError, isSuccess, data, error, activeItemId]);

  const { hasAnyRoles } = useAuthUser();

  const hasPermissionToViewEditButton = hasAnyRoles([
    "AppStoreKonfiguration_lesen",
    "AppStoreKonfiguration_schreiben",
  ]);

  return (
    <AutobahnBackground>
      <div className="system-overview">{content}</div>
      <div className="logo-abs">
        <img src={logo} alt="Autobahn-Logo" />
      </div>
      <div className="floating-link-container">
        <FloatingLink icon={faSignOut} link="/logout"></FloatingLink>
        {hasPermissionToViewEditButton && (
          <FloatingLink link="/admin"></FloatingLink>
        )}
      </div>
    </AutobahnBackground>
  );
}
