interface IconProps {
  iconClassName: string;
}

export default function Icon({ iconClassName }: IconProps) {
  return (
    <span className="icon has-text-white">
      <i className={iconClassName}></i>
    </span>
  );
}
