import App from "./App";
import { AuthProvider } from "react-oidc-context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Route, Routes } from "react-router-dom";
import Logout from "./Logout";

const oidcConfig = {
  authority: import.meta.env.VITE_OIDC_CLIENT_AUTHORITY,
  client_id: import.meta.env.VITE_OIDC_CLIENT_ID,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
    },
  },
});

export default function AppWithAuthentication() {
  function onSignInCallback() {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("state");
    searchParams.delete("session_state");
    searchParams.delete("code");

    const hasParams = Array.from(searchParams.values()).length > 0;
    const url = hasParams
      ? `${window.location.pathname}?${searchParams.toString()}`
      : window.location.pathname;

    window.history.replaceState({}, document.title, url);
  }

  function redirectUriCallback() {
    return (
      window.location.origin + window.location.pathname + window.location.search
    );
  }

  return (
    <AuthProvider
      authority={oidcConfig.authority}
      client_id={oidcConfig.client_id}
      onSigninCallback={onSignInCallback}
      redirect_uri={redirectUriCallback()}
    >
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="*" element={<App />}></Route>
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </QueryClientProvider>
    </AuthProvider>
  );
}
