import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppWithAuthentication from "./AppWithAuthentication";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        limit={3}
        closeButton={false}
        closeOnClick={false}
        draggable={false}
      />
      <AppWithAuthentication />
    </BrowserRouter>
  </React.StrictMode>,
);
