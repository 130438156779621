import SystemOverviewItem from "./SystemOverviewItem";
import { Dispatch, SetStateAction } from "react";
import AppGroup from "../react-query/app-group/AppGroup";

interface SystemOverviewGroupProps {
  activeItemId: string | null;
  group: AppGroup;
  setActiveItemId: Dispatch<SetStateAction<string | null>>;
}

export default function SystemOverviewGroup({
  activeItemId,
  group,
  setActiveItemId,
}: SystemOverviewGroupProps) {
  return (
    <div className="system-overview__group">
      <h2 className="system-overview__group-title">{group.title}</h2>
      <div className="system-overview--items">
        {group.apps.map((item) => {
          const itemId = `${group.title}${item.title}`;
          return (
            <SystemOverviewItem
              key={item.abbreviation}
              item={item}
              isActive={activeItemId === itemId}
              onToggleActive={() =>
                setActiveItemId((currentActiveId) =>
                  currentActiveId === itemId ? null : itemId,
                )
              }
            />
          );
        })}
      </div>
    </div>
  );
}
