export class FetchError extends Error {
  constructor(
    message: string,
    public isNetworkError: boolean,
    public status?: number,
    public statusText?: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public body?: any,
  ) {
    super(message);
  }

  isUnauthorizedError(): boolean {
    return this.status === 401;
  }

  toString() {
    return `${this.status} ${this.statusText}`;
  }
}
