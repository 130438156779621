const ApiPrefix = "/api";

class MutationPath {
  static readonly CreateConfiguration = new MutationPath(
    `${ApiPrefix}/configuration`,
  );

  private readonly path: string;

  private constructor(path: string) {
    this.path = path;
  }

  public toString() {
    return this.path;
  }
}

export default MutationPath;
