import { PropsWithChildren } from "react";

interface ErrorMessageProps {
  title: string;
}

export default function ErrorMessage({
  children,
  title,
}: PropsWithChildren<ErrorMessageProps>) {
  return (
    <article className="message is-danger mx-6 my-4">
      <p className="message-header">{title}</p>
      <div className="message-body content">{children}</div>
    </article>
  );
}
