import bg from "@e21x/autobahn-styleguide/assets/login-bg.jpg";
import React from "react";

export default function AutobahnBackground({
  children,
}: React.PropsWithChildren) {
  return (
    <div
      className="system-overview-layout"
      style={{ backgroundImage: `url('${bg}')` }}
    >
      {children}
    </div>
  );
}
