import MutationKey from "./MutationKey";
import useCustomFetch from "../CustomFetch";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import MutationPath from "./MutationPath";
import { FetchError } from "../FetchError";

export type FetchMutationVariables<RequestBodyType> = {
  body: RequestBodyType;
  path: MutationPath;
  requestMethod: "POST";
};

export type PostMutationVariables<RequestBodyType> = Omit<
  FetchMutationVariables<RequestBodyType>,
  "requestMethod"
>;

export function usePostMutation<RequestBodyType, ResponseBodyType>(
  mutationKey: MutationKey,
  options?: UseMutationOptions<
    ResponseBodyType,
    FetchError,
    PostMutationVariables<RequestBodyType>
  >,
): UseMutationResult<
  ResponseBodyType,
  FetchError,
  PostMutationVariables<RequestBodyType>
> {
  const customFetch = useCustomFetch();
  return useMutation({
    mutationKey: [mutationKey],
    mutationFn: async ({
      body,
      path,
    }: PostMutationVariables<RequestBodyType>) => {
      const response = await customFetch(path.toString(), {
        method: "POST",
        body: body ? JSON.stringify(body) : undefined,
      });
      return response as ResponseBodyType;
    },
    ...options,
  });
}
