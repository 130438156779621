import { useAuth } from "react-oidc-context";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { UserRole } from "./userRole";
import { User } from "oidc-client-ts";

interface JwtTokenRoles {
  roles?: string[];
}

interface OidcAccessToken {
  realm_access?: JwtTokenRoles;
  sub: string;
}

export const useAuthUser = () => {
  const { user } = useAuth();

  const [userRoles, setUserRoles] = useState<string[]>(getUserRoles(user));

  useEffect(() => {
    setUserRoles(getUserRoles(user));
  }, [user]);

  const hasRole = (role: UserRole) => userRoles.includes(role);

  const hasAnyRoles = (roles: UserRole[]) => roles.some(hasRole);

  return { hasRole, hasAnyRoles };
};

function getUserRoles(authUser: User | null | undefined): string[] {
  if (!authUser) {
    return [];
  }

  const decodedToken = jwtDecode<OidcAccessToken>(authUser.access_token);

  if (!decodedToken.realm_access?.roles) {
    return [];
  }
  return decodedToken.realm_access.roles;
}
