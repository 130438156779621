import { useEffect } from "react";
import { useAuth } from "react-oidc-context";

function Logout() {
  const { signoutRedirect } = useAuth();

  useEffect(() => {
    signoutRedirect({
      post_logout_redirect_uri: window.location.origin,
    }).catch((error) => console.error(`Signout failed: ${error}`));
  }, [signoutRedirect]);

  return <div>Ausloggen...</div>;
}

export default Logout;
