import useCustomFetch from "./CustomFetch";
import ServerStateKeysEnum from "./ServerStatekeysEnum";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { FetchError } from "./FetchError";

type ServerStateQueryKey =
  | [ServerStateKeysEnum, ...Array<unknown>]
  | ServerStateKeysEnum;

export function useGetQuery<ResponseBodyType>(
  queryKey: ServerStateQueryKey,
  path: string,
  options?: UseQueryOptions<ResponseBodyType, FetchError>,
) {
  const customFetch = useCustomFetch();

  return useQuery<ResponseBodyType, FetchError>({
    queryKey: Array.isArray(queryKey) ? queryKey : [queryKey],
    queryFn: async () => {
      const response = await customFetch(path);
      return (await response) as ResponseBodyType;
    },
    ...options,
  });
}
