import { useGetQuery } from "../GetQuery";
import ServerStateKeysEnum from "../ServerStatekeysEnum";
import AppGroup from "./AppGroup";

export function useGetConfiguration() {
  return useGetQuery<AppGroup[]>(
    ServerStateKeysEnum.Configuration,
    "/api/configuration",
  );
}
