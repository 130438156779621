import AutobahnBackground from "../AutobahnBackground/AutobahnBackground";
import "./pageNotFound.scss";
import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <AutobahnBackground>
      <div className="pageNotFound">
        <h1 className="pageNotFound__title">404 - Seite nicht gefunden</h1>
        <div className="pageNotFound__note">
          <p>Die Seite, die Sie aufgerufen haben, existiert nicht.</p>
          <p>
            Gehen Sie zurück zur <Link to="/">Startseite</Link>.
          </p>
        </div>
      </div>
    </AutobahnBackground>
  );
}
