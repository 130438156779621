interface ValidationError {
  message: string;
  path: (string | number)[];
  expected: string;
  received: string;
}

function getObjectPath(path: (string | number)[]) {
  let objectPath = "";
  for (let i = 0; i < path.length; i++) {
    if (typeof path[i] === "string") {
      objectPath = `${objectPath}.${path[i]}`;
    }
    if (typeof path[i] === "number") {
      objectPath = `${objectPath}[${path[i]}]`;
    }
  }

  return objectPath;
}

interface ValidationErrorMessageProps {
  errors: ValidationError[];
}

export default function ValidationErrorMessages({
  errors,
}: ValidationErrorMessageProps) {
  return (
    <>
      {errors.map((error: { message: string; path: (string | number)[] }) => (
        <li key={error.message}>
          <>
            <span className="has-text-weight-bold">
              {getObjectPath(error.path)}:{" "}
            </span>
            <span>{error.message}</span>
          </>
        </li>
      ))}
    </>
  );
}
