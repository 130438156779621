import { useAuth } from "react-oidc-context";

const jsonContentType = "application/json";
const jsonCharsetContentType = `${jsonContentType}; charset=utf-8`;
const jsonHateoasContentType = "application/hal+json";

export function useDefaultHeaders() {
  const { user } = useAuth();
  const headers = new Headers();
  headers.set("Content-Type", jsonContentType);
  headers.set("Accept", jsonContentType);
  if (user) {
    headers.set("Authorization", `Bearer ${user?.access_token}`);
  }

  return headers;
}

export const hasJsonBody = (response: Response) => {
  const contentType = response.headers.get("Content-Type");

  return (
    contentType != null &&
    [jsonContentType, jsonHateoasContentType, jsonCharsetContentType].indexOf(
      contentType,
    ) !== -1
  );
};
