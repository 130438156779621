import Icon from "../ui/Icon";
import AppItem from "../react-query/app-group/AppItem";

interface SystemOverviewItemProps {
  item: AppItem;
  isActive: boolean;
  onToggleActive: () => void;
}

export default function SystemOverviewItem({
  item,
  isActive,
  onToggleActive,
}: SystemOverviewItemProps) {
  return (
    <div className="system-overview--item">
      <div className={`system-card${isActive ? " active" : ""}`}>
        <div className="system-card--overlay">
          <div className="system-card--systemName">
            {item.links.ref ? (
              <a className="link" href={item.links.ref}>
                Referenzsystem
              </a>
            ) : (
              <span className="no-link-provided">Referenzsystem</span>
            )}
          </div>
          <div className="system-card--systemName">
            {item.links.test ? (
              <a className="link" href={item.links.test}>
                Testsystem
              </a>
            ) : (
              <span className="no-link-provided">Testsystem</span>
            )}
          </div>
        </div>
        {(item.links.ref || item.links.test) && (
          <button
            type="button"
            className="system-card--more-btn"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onToggleActive();
            }}
          >
            {isActive ? (
              <Icon iconClassName="fas fa-times" />
            ) : (
              <Icon iconClassName="fas fa-ellipsis-h" />
            )}
          </button>
        )}
        <a href={item.links.prod} className="system-card--titleKey">
          {item.abbreviation.substring(0, 2)}
        </a>
      </div>
      <a href={item.links.prod} className="system-card--title">
        {item.title}
      </a>
    </div>
  );
}
